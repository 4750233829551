
import card1 from './card-1.webp'
import card1P from './card-1-page.webp'
import card2 from './card-2.webp'
import card2P from './card-2-page.webp'
import card3 from './card-3.webp'
import card3P from './card-3-page.webp'
import card4 from './card-4.webp'
import card4P from './card-4-page.webp'
import card5 from './card-5.webp'
import card5P from './card-5-page.webp'
import card6 from './card-6.webp'
import card6P from './card-6-page.webp'
import card7 from './card-7.webp'
import card7P from './card-7-page.webp'
import card8 from './card-8.webp'
import card8P from './card-8-page.webp'
import card9 from './card-9.webp'
import card9P from './card-9-page.webp'
import card10 from './card-10.webp'
import card10P from './card-10-page.webp'
import card11 from './card-11.webp'
import card11P from './card-11-page.webp'
import card12 from './card-12.webp'
import card12P from './card-12-page.webp'

const ServicesData = [
    {
        id: 1,
        name: "إعداد صياغة العقود وتوثيقها",
        img: card1,
        Pageimg: card1P,
        description: "نقدم مراجعة العقود وصياغتها، الاتفاقيات ومذكرات التفاهم مع مراعاة المعايير المطلوبة لرغبة عملائنا",
        note: "في شركة إبراهيم الزهراني وأحمد المالكي للمحاماة والاستشارات القانونية، نقدم خدمات متكاملة في إعداد وصياغة العقود والاتفاقيات، حيث نضمن أن تكون العقود محكمة الصياغة ومتوافقة مع الأنظمة المعمول بها في المملكة العربية السعودية، بما يحقق مصالح عملائنا ويحمي حقوقهم.",

        topic1H: "ما هي خدمة إعداد وصياغة وتوثيق العقود ؟",
        topic1D: "هي خدمة قانونية متخصصة تشمل مراجعة، تدقيق، و صياغة العقود , وتوثيق العقود والاتفاقيات بجميع أنواعها، بما في ذلك مذكرات التفاهم، لضمان وضوح البنود والالتزام بالمعايير القانونية، مع مراعاة رغبات العملاء وتطلعاتهم المستقبلية.",

        topic2H: "لماذا تختار خدماتنا في إعداد العقود؟",
        topic2Dli1: "✅ صياغة قانونية دقيقة: نعد عقودًا محكمة الصياغة لتجنب أي ثغرات قانونية مستقبلية.",
        topic2Dli2: "✅ مراجعة شاملة للعقود: ندقق جميع البنود لضمان توافقها مع القوانين والأنظمة المحلية.",
        topic2Dli3: "✅ حماية الحقوق والمصالح: نضمن أن يكون العقد عادلًا ومتوازنًا لجميع الأطراف.",
        topic2Dli4: "✅ إعداد كافة أنواع العقود: سواء عقود تجارية، عقود شراكة، عقود عمل، عقود تأجير، أو أي نوع آخر.",
        topic2Dli5: "✅ استشارات متخصصة: نقدم استشارات قانونية حول بنود العقود لضمان أفضل الصيغ القانونية.",

        topic3H: "آلية العمل في إعداد العقود",
        topic3Dli1: "📜 الاجتماع مع العميل: فهم احتياجاته والمتطلبات الخاصة بالعقد.",
        topic3Dli2: "📜 تحليل وتقييم البنود: مراجعة البنود المقترحة لضمان الامتثال القانوني.",
        topic3Dli3: "📜 الصياغة القانونية: إعداد العقد بأسلوب احترافي وواضح.",
        topic3Dli4: "📜 المراجعة والتعديل: إجراء أي تعديلات لضمان تحقيق مصلحة العميل.",
        topic3Dli5: "📜 الاعتماد النهائي والتوثيق: تسليم العقد الجاهز وفقًا للمتطلبات القانونية.",

    },
    {
        id: 2,
        name: "التقاضي",
        img: card2,
        Pageimg: card2P,
        description: "لدينا فريق مؤهل وخبير في تمثيل موكلينا أمام مختلف المحاكم وديوان المظالم واللجان القضائية وجهات التحقيق داخل المملكة وخارجها.",
        note: "في شركة إبراهيم الزهراني وأحمد المالكي للمحاماة والاستشارات القانونية، نقدم خدمات التقاضي باحترافية تامة، حيث نمثل موكلينا أمام جميع المحاكم، ديوان المظالم، اللجان القضائية، وجهات التحقيق داخل المملكة العربية السعودية وخارجها، وفقًا لأعلى المعايير القانونية.",

        topic1H: "ما هي خدمة التقاضي؟",
        topic1D: "هي خدمة قانونية متخصصة تهدف إلى الدفاع عن حقوق الموكلين أمام الجهات القضائية، من خلال الترافع، تقديم الدفوع القانونية، وإعداد المذكرات اللازمة لضمان أفضل النتائج القانونية الممكنة.",

        topic2H: "لماذا تختار خدماتنا في التقاضي؟",
        topic2Dli1: "✅ فريق قانوني مؤهل: يضم مكتبنا محامين معتمدين يمتلكون خبرة واسعة في أنظمة القضاء السعودي.",
        topic2Dli2: "✅ متابعة دقيقة للمرافعات: نقدم دعمًا مستمرًا لموكلينا خلال جميع مراحل القضية.",
        topic2Dli3: "✅ إعداد مذكرات قانونية دقيقة: نعتمد على التحليل العميق لكل قضية لضمان تقديم أقوى الدفوع القانونية.",
        topic2Dli4: "✅ تمثيل قضائي شامل: نمثل عملاءنا في الدعاوى المدنية، التجارية، الجنائية، العمالية، والأحوال الشخصية.",
        topic2Dli5: "✅ التزام بالسرية والاحترافية: نضمن أعلى مستويات الخصوصية في معالجة القضايا.",

        topic3H: "آلية العمل في التقاضي",
        topic3Dli1: "⚖ دراسة القضية: تحليل شامل للأدلة والمستندات القانونية.",
        topic3Dli2: "⚖ وضع الاستراتيجية القانونية: إعداد خطة محكمة للدفاع عن حقوق الموكل.",
        topic3Dli3: "⚖ المرافعة أمام المحاكم: تقديم الدفوع القانونية والترافع باحترافية.",
        topic3Dli4: "⚖ متابعة مستمرة: تقديم تقارير دورية لموكلينا حول تطورات القضايا.",
    },
    {
        id: 3,
        name: "الاستشارات الشرعية والقانونية",
        img: card3,
        Pageimg: card3P,
        description: "تقديم المشورة والنصيحة الشرعية والقانونية، وتحليلها ودراستها مع بيان نقاط القوة وتقديم الحلول المناسبة.",
        note: "نقدم في شركة ابراهيم الزهراني وأحمد المالكي للمحاماة والاستشارات القانونية خدمات الاستشارات الشرعية والقانونية وفقًا لأحدث الأنظمة واللوائح المعمول بها في المملكة العربية السعودية. سواء كنت فردًا أو شركة، نوفر لك تحليلًا دقيقًا لموقفك القانوني مع تقديم الحلول المناسبة لحمايتك وضمان حقوقك.",

        topic1H: "ما هي خدمة الاستشارات الشرعية والقانونية ؟",
        topic1D: "هي خدمة تهدف إلى تقديم المشورة والنصيحة بناءً على القوانين والتشريعات المعتمدة في المملكة، بالإضافة إلى الأحكام الشرعية المستندة إلى الشريعة الإسلامية. نقوم بدراسة كل حالة بشكل دقيق وتحليل جميع الجوانب القانونية لتقديم أفضل الحلول التي تضمن لك الأمان القانوني.",

        topic2H: "ما يميزنا في تقديم الاستشارات الشرعية والقانونية ؟",
        topic2Dli1: "خبرة قانونية وشرعية متكاملة: يضم فريقنا نخبة من المحامين والمستشارين القانونيين المتخصصين في القانون السعودي والفقه الإسلامي",
        topic2Dli2: " تحليل دقيق للحالة القانونية: نقوم بدراسة كل حالة بشكل منفصل لتقديم استشارات دقيقة تتناسب مع احتياجات العميل.",
        topic2Dli3: "سرية تامة وضمان خصوصية العملاء: نلتزم بأعلى معايير الأمان والخصوصية في التعامل مع القضايا والاستشارات.",

        topic3H: "آلية العمل في تقديم الاستشارات",
        topic3Dli1: " حجز موعد استشارة عبر الموقع الإلكتروني أو الهاتف",
        topic3Dli2: " تقديم تفاصيل الحالة القانونية لمستشارينا لتحليله",
        topic3Dli3: "دراسة القضية من جميع الجوانب الشرعية والقانونية",
        topic3Dli4: "تقديم الحلول والخيارات القانونية المتاحة مع شرح مفصل",
        topic3Dli5: "متابعة مستمرة ودعم قانوني متكامل حتى تحقيق النتائج المطلوبة",
    },
    {
        id: 4,
        name: "الحوكمة والالتزام القانوني",
        img: card4,
        Pageimg: card4P,
        description: "ضمان العمليات المبدئية والالتزام بقيم الشركات والممارسات التجارية والتأكد من الامتثال لها",
        note: "في شركة إبراهيم الزهراني وأحمد المالكي للمحاماة والاستشارات القانونية، نقدم خدمات الحوكمة والتزام القانوني لضمان امتثال الشركات والمؤسسات بالأنظمة والتشريعات المعمول بها في المملكة العربية السعودية. نساعد الشركات في وضع إطار قانوني وتنظيمي يحمي أعمالها ويضمن استدامتها وفقًا لأفضل الممارسات التجارية وأعلى معايير الشفافية والنزاهة.",

        topic1H: "ما هي خدمة الحوكمة والامتثال القانوني؟",
        topic1D: "هي خدمة تهدف إلى تعزيز الرقابة الداخلية وضمان التزام الشركات باللوائح والقوانين، مما يساهم في تحسين الأداء المؤسسي وتقليل المخاطر القانونية. تشمل هذه الخدمة تصميم سياسات الحوكمة، التأكد من الامتثال للأنظمة، وتقديم الاستشارات القانونية لضمان عدم التعرض للمخالفات.",

        topic2H: "لماذا تحتاج شركتك إلى الحوكمة والامتثال القانوني؟",
        topic2Dli1: "✅ حماية الشركة من المخاطر القانونية: نساعدك على تجنب الغرامات والمخالفات.",
        topic2Dli2: "✅ تعزيز الشفافية والمصداقية: نضمن التزام شركتك بأفضل الممارسات التجارية.",
        topic2Dli3: "✅ تحسين استدامة الأعمال: تحقيق بيئة عمل منظمة ومتوافقة مع القوانين.",
        topic2Dli4: "✅ إعداد سياسات الامتثال والرقابة الداخلية: لتجنب الأخطاء القانونية والتنظيمية.",
        topic2Dli5: "✅ ضمان توافق العمليات مع الأنظمة السعودية: الالتزام باللوائح الخاصة بهيئة السوق المالية، وزارة التجارة، وهيئات الرقابة الأخرى.",

        topic3H: "آلية العمل في الحوكمة والامتثال القانوني",
        topic3Dli1: "📑 تقييم الامتثال القانوني: مراجعة سياسات وإجراءات الشركة الحالية.",
        topic3Dli2: "⚖ وضع إطار الحوكمة: تطوير أنظمة ولوائح داخلية تضمن الامتثال للقوانين.",
        topic3Dli3: "📋 تدريب الموظفين والإدارة: توعية الفريق بأهمية الامتثال القانوني.",
        topic3Dli4: "🔍 متابعة الالتزام والتحديث المستمر: ضمان استمرارية الامتثال مع التغيرات القانونية.",
        topic3Dli5: "✅ تقديم حلول قانونية لأي تحديات تنظيمية: دعم الشركات في تنفيذ المتطلبات القانونية بفعالية.",
    },
    {
        id: 5,
        name: "التحكيم",
        img: card5,
        Pageimg: card5P,
        description: "لدينا خبرة في إدارة وتنظيم التحكيم حيث تتم الإجراءات بجلسات غير علنية",
        note: "في شركة إبراهيم الزهراني وأحمد المالكي للمحاماة والاستشارات القانونية، نقدم خدمات التحكيم وفق أعلى المعايير القانونية، حيث نساعد الأفراد والشركات في حل النزاعات بطريقة احترافية وسرية، بعيدًا عن تعقيدات المحاكم التقليدية. يتميز فريقنا بخبرة واسعة في إدارة وتنظيم إجراءات التحكيم، مع ضمان تنفيذ الجلسات في بيئة محايدة وغير علنية لتحقيق العدالة بكفاءة وسرعة.",

        topic1H: "ما هي خدمة التحكيم؟",
        topic1D: "التحكيم هو وسيلة بديلة لحل النزاعات، يتم فيها الفصل في القضايا من قبل هيئة تحكيم محايدة، دون الحاجة إلى اللجوء إلى القضاء الرسمي. يتميز التحكيم بالسرعة، السرية، والكفاءة في إنهاء النزاعات التجارية والمدنية وفقًا للقوانين المعمول بها في المملكة العربية السعودية.",

        topic2H: "لماذا تختار خدماتنا في التحكيم؟",
        topic2Dli1: "✅ جلسات سرية وغير علنية: نوفر بيئة تحكيمية تحافظ على خصوصية الأطراف.",
        topic2Dli2: "✅ سرعة البت في النزاعات: التحكيم يوفر حلولًا أسرع مقارنة بالإجراءات القضائية التقليدية.",
        topic2Dli3: "✅ محكمون معتمدون وذوو خبرة: يضم فريقنا نخبة من المحكمين المتخصصين في مختلف المجالات القانونية.",
        topic2Dli4: "✅ إجراءات مرنة ومحايدة: نحرص على تحقيق العدالة بأقصى درجات النزاهة والاستقلالية.",
        topic2Dli5: "✅ تنفيذ الأحكام التحكيمية بفعالية: نضمن تنفيذ قرارات التحكيم وفق الأطر القانونية.",

        topic3H: "آلية العمل في التحكيم",
        topic3Dli1: "⚖ الاتفاق على التحكيم: توقيع اتفاق بين الأطراف لتحديد نطاق التحكيم.",
        topic3Dli2: "📜 تعيين هيئة التحكيم: اختيار محكمين متخصصين وفقًا لطبيعة النزاع.",
        topic3Dli3: "📝 عرض الأدلة والمرافعات: تقديم المستندات والمرافعات أمام هيئة التحكيم.",
        topic3Dli4: "🔍 إصدار الحكم التحكيمي: يتم إصدار القرار التحكيمي الملزم للأطراف.",
        topic3Dli5: "✅ تنفيذ الحكم: متابعة تنفيذ القرار من خلال الجهات المختصة.",
    },
    {
        id: 6,
        name: "تأسيس شركة وافلاسها",
        img: card6,
        Pageimg: card6P,
        description: "إعداد عقود تأسيس الشركات وإنهاء كافة الإجراءات، ومعرفة حالة العميل لتحديد الإجراء المناسب له بالتصفية أو إعادة التنظيم المالي",
        note: "في شركة إبراهيم الزهراني وأحمد المالكي للمحاماة والاستشارات القانونية، نقدم خدمات متخصصة في مجال التجارة والإفلاس، حيث نوفر الدعم القانوني لرواد الأعمال والشركات في جميع مراحل تأسيس شركة جديدة ، بالإضافة إلى تقديم الحلول القانونية المناسبة للشركات التي تواجه تحديات مالية، سواء عبر إعادة التنظيم المالي أو التصفية وفقًا لأحكام نظام الإفلاس السعودي.",

        topic1H: "ما هي خدمة تأسيس الشركة ؟",
        topic1D: "تشمل هذه الخدمة تقديم استشارات وإجراءات قانونية متكاملة، بدءًا من إعداد عقود تأسيس الشركات وإتمام جميع المتطلبات القانونية،  نساعدك في تأسيس شركتك وفقًا للقوانين السعودية بأقل جهد ووقت ,  نضمن أن تكون جميع العقود متوافقة مع اللوائح التجارية لحماية مصالحك.",

        topic2H: "خدمة افلاس الشركة واعادة التنظيم المالي:",
        topic2Dli1: "✅ حلول قانونية متكاملة في حالات الإفلاس: نوفر استراتيجيات قانونية للخروج الآمن من الأزمات المالية.",
        topic2Dli2: "✅ استشارات متخصصة في إعادة التنظيم المالي: نساعد الشركات على إعادة هيكلة التزاماتها المالية لضمان الاستمرارية.",
        topic2Dli3: "✅ إجراءات تصفية منظمة: نقدم حلولًا قانونية لإنهاء النشاط التجاري وفقًا للأنظمة المعتمدة.",

        topic3H: "آلية العمل في عقود التأسيس والإفلاس",
        topic3Dli1: "🏢 إعداد عقود التأسيس: توثيق وتسجيل الشركات وفق الأنظمة السعودية.",
        topic3Dli2: "📊 تحليل الوضع المالي للعميل: تقييم حالة الشركة لتحديد الإجراء القانوني المناسب.",
        topic3Dli3: "⚖ وضع خطة إعادة التنظيم المالي: تقديم حلول لإعادة هيكلة الالتزامات المالية.",
        topic3Dli4: "📑 إجراءات التصفية والإفلاس: تنفيذ كافة الإجراءات القانونية لإنهاء النشاط التجاري بسلاسة.",
    },
    {
        id: 7,
        name: "المنازعات العقارية",
        img: card7,
        Pageimg: card7P,
        description: "خبرة في المنازعات العقارية المتعلقة بالصكوك والعقود العقارية السكنية والتجارية، والتطوير العقاري وصناديق الاستثمار العقاري.",
        note: "في شركة إبراهيم الزهراني وأحمد المالكي للمحاماة والاستشارات القانونية، نتمتع بخبرة واسعة في التعامل مع المنازعات العقارية التي تشمل الصكوك، العقود العقارية السكنية والتجارية، والتطوير العقاري، وصناديق الاستثمار العقاري. نحن نعمل على حماية حقوق عملائنا من خلال تقديم استشارات قانونية متخصصة تضمن تسوية المنازعات بأفضل الطرق القانونية.",

        topic1H: "ما هي خدمة المنازعات العقارية؟",
        topic1D: "خدمة تهدف إلى تقديم استشارات قانونية وحلول فعالة للمنازعات التي قد تنشأ في القطاع العقاري، سواء كانت تتعلق بالصكوك، العقود العقارية، أو مشاكل تتعلق بالتطوير العقاري وصناديق الاستثمار العقاري. نحن نعمل على حل النزاعات من خلال التفاوض، التحكيم، أو التقاضي أمام المحاكم المختصة.",

        topic2H: "لماذا تختار خدماتنا في المنازعات العقارية؟",
        topic2Dli1: "✅ خبرة قانونية متخصصة: لدينا فريق من المحامين المتخصصين في القضايا العقارية بما في ذلك الصكوك والعقود العقارية.",
        topic2Dli2: "✅ استشارات قانونية دقيقة: نقدم تحليلاً قانونياً دقيقاً للمنازعات العقارية لضمان حماية الحقوق وحل النزاعات بسرعة وفعالية.",
        topic2Dli3: "✅ حلول مبتكرة: نعمل على إيجاد حلول قانونية مبتكرة لحل المنازعات العقارية بأقل التكاليف والوقت.",
        topic2Dli4: "✅ تمثيل قانوني أمام المحاكم: نتمتع بخبرة في تمثيل عملائنا أمام المحاكم المختصة لحل القضايا العقارية.",
        topic2Dli5: "✅ مساعدة في تطوير العقارات: استشارات قانونية متعلقة بعقود التطوير العقاري والصناديق الاستثمارية.",

        topic3H: "آلية العمل في حل المنازعات العقارية",
        topic3Dli1: "📑 تقييم الوضع القانوني: دراسة جميع المستندات والعقود المتعلقة بالنزاع العقاري.",
        topic3Dli2: "⚖ التفاوض والتسوية الودية: السعي لحل النزاع من خلال التفاوض المباشر والتسويات الودية.",
        topic3Dli3: "📋 التقاضي والتحكيم: إذا لزم الأمر، نقدم تمثيلاً قانونياً أمام المحاكم أو في جلسات التحكيم العقاري.",
        topic3Dli4: "🔍 مراقبة جميع جوانب القضية: متابعة القضية بشكل مستمر لضمان تسوية النزاع في أسرع وقت ممكن.",

        topic4H: "خدماتنا تشمل:",
        topic4Dli1: "⚖ حل المنازعات العقارية: تقديم استشارات وحلول قانونية لحل المنازعات العقارية المتعلقة بالصكوك، العقود السكنية والتجارية.",
        topic4Dli2: "📜 إعداد ومراجعة العقود العقارية: صياغة عقود بيع وشراء، عقود الإيجار، وعقود تطوير المشاريع العقارية.",
        topic4Dli3: "🏘 منازعات العقارات السكنية والتجارية: التعامل مع القضايا المتعلقة بالعقارات السكنية والتجارية مثل المطالبات المالية، الإيجارات، والملكية.",
        topic4Dli4: "🏢 استشارات في التطوير العقاري: تقديم المشورة القانونية في مشاريع التطوير العقاري وصناديق الاستثمار العقاري.",

    },
    {
        id: 8,
        name: "البنوك والتمويل والتأمين",
        img: card8,
        Pageimg: card8P,
        description: "تقديم خدمات للبنوك وشركات التمويل والتأمين في صياغة العقود وتقليل المخاطر وتحصيل الديون المتعثرة",
        note: "في شركة إبراهيم الزهراني وأحمد المالكي للمحاماة والاستشارات القانونية، نقدم خدمات قانونية متخصصة للبنوك وشركات التمويل والتأمين، تشمل صياغة العقود، تقليل المخاطر القانونية، وتحصيل الديون المتعثرة، مع الحرص على ضمان الامتثال للقوانين والتشريعات السعودية في هذه القطاعات الحيوية.",

        topic1H: "ما هي خدماتنا للبنوك وعقود التمويل والتأمين؟",
        topic1D: "نقدم خدمات قانونية متكاملة تهدف إلى حماية المصالح القانونية للبنوك وشركات التمويل والتأمين، بما يشمل إعداد العقود، التقليل من المخاطر القانونية، تحصيل الديون المتعثرة، وتحقيق أفضل حلول قانونية لضمان استدامة هذه الأنشطة.",

        topic2H: "خدماتنا تشمل: ",
        topic2Dli1: "✅ صياغة عقود التمويل والتأمين: ضمان صياغة عقود متوافقة مع الأنظمة المالية والتجارية السعودية.",
        topic2Dli2: "✅ إدارة المخاطر القانونية: تقديم استشارات قانونية تساعد في تقليل المخاطر المرتبطة بالأنشطة المالية.",
        topic2Dli3: "✅ تحصيل الديون المتعثرة: استراتيجيات قانونية لتحصيل الديون بفعالية وفقًا للأنظمة المعمول بها.",
        topic2Dli4: "✅ الامتثال للقوانين المالية: ضمان التزام البنوك والشركات المالية باللوائح المصرفية والتنظيمية في السعودية.",
        topic2Dli5: "✅ استشارات قانونية في قطاع التأمين: تقديم حلول قانونية لتنظيم عقود التأمين وضمان حقوق الأطراف المعنية.",

        topic3H: "آلية العمل في خدمات البنوك والتمويل والتأمين",
        topic3Dli1: "📑 مراجعة العقود الحالية: دراسة العقود والاتفاقيات لضمان امتثالها للقوانين المعمول بها.",
        topic3Dli2: "⚖ تقديم استشارات قانونية: تحليل المخاطر القانونية وتقديم حلول فعالة لتقليلها.",
        topic3Dli3: "📋 إجراءات تحصيل الديون: تقديم استراتيجيات قانونية لتحصيل الأموال المتعثرة مع الحفاظ على علاقة جيدة مع العملاء.",
        topic3Dli4: "🔍 إعداد تقارير قانونية دورية: متابعة التغييرات القانونية في القطاع المصرفي والمالي وتقديم التوصيات اللازمة.",
    },
    {
        id: 9,
        name: "الاستثمار الأجنبي",
        img: card9,
        Pageimg: card9P,
        description: "تقديم خدمات تأسيس الشركات الأجنبية ومتابعة إصدار التراخيص والسجلات التجارية وتمثيل المستثمر الأجنبي أمام الجهات الرسمية.",
        note: "في شركة إبراهيم الزهراني وأحمد المالكي للمحاماة والاستشارات القانونية، نقدم خدمات شاملة لدعم الاستثمار الأجنبي في المملكة العربية السعودية، من خلال تأسيس الشركات الأجنبية، إصدار التراخيص، متابعة السجلات التجارية، وتمثيل المستثمر الأجنبي أمام الجهات الرسمية، بما يضمن الامتثال للأنظمة والتشريعات السعودية وتسهيل عمليات الاستثمار.",

        topic1H: "ما هي خدمة الاستثمار الأجنبي؟",
        topic1D: "هي خدمة قانونية متخصصة تتيح للمستثمرين الأجانب تأسيس شركاتهم في السعودية وفقًا للوائح وزارة الاستثمار السعودية، مع توفير الدعم القانوني الكامل لضمان سير العمليات التجارية بسلاسة وأمان قانوني.",

        topic2H: "لماذا تختار خدماتنا في الاستثمار الأجنبي؟",
        topic2Dli1: "✅ إجراءات قانونية ميسّرة: متابعة كافة المتطلبات القانونية والإدارية لتأسيس الشركات الأجنبية.",
        topic2Dli2: "✅ استشارات قانونية متخصصة: تقديم حلول قانونية واستراتيجية لضمان الامتثال للأنظمة السعودية.",
        topic2Dli3: "✅ تمثيل قانوني أمام الجهات الرسمية: التعامل مع الوزارات والهيئات الحكومية نيابة عن المستثمر.",
        topic2Dli4: "✅ إصدار التراخيص والسجلات التجارية: متابعة استخراج التراخيص المطلوبة بسرعة وكفاءة.",
        topic2Dli5: "✅ دعم شامل في جميع مراحل الاستثمار: من التأسيس إلى التشغيل وضمان استدامة الأعمال.",

        topic3H: "خطوات الاستثمار الأجنبي في السعودية",
        topic3Dli1: "📑 تحديد النشاط التجاري المناسب للمستثمر وفقًا للأنظمة السعودية.",
        topic3Dli2: "📋 إعداد المستندات والتراخيص اللازمة للحصول على الموافقات المطلوبة.",
        topic3Dli3: "⚖ تسجيل الشركة وإصدار السجل التجاري بالتنسيق مع وزارة الاستثمار السعودية.",
        topic3Dli4: "🔍 ضمان الامتثال للمتطلبات الضريبية والتنظيمية وفق اللوائح المعمول بها.",
        topic3Dli5: "📞 تقديم الاستشارات القانونية الدورية لضمان استمرارية الامتثال وحماية الاستثمارات.",
    },
    {
        id: 10,
        name: "قضايا الأوقاف والنظارة والوصايا",
        img: card10,
        Pageimg: card10P,
        description: "خبرة في القضايا الوقفية مثل عزل الناظر ومحاسبته، وصياغة الوقفية والوصايا وإثباتها أمام المحكمة واستكمال تراخيص الوقفية.",
        note: "في شركة إبراهيم الزهراني وأحمد المالكي للمحاماة والاستشارات القانونية، نقدم خدمات قانونية متكاملة في مجال الأوقاف والنظارة والوصايا، مع خبرة واسعة في التعامل مع القضايا الوقفية مثل عزل الناظر ومحاسبته، بالإضافة إلى صياغة الوقفيات والوصايا وإثباتها أمام المحاكم واستكمال إجراءات تراخيص الوقفية.",

        topic1H: "ما هي خدمة الأوقاف والنظارة والوصايا؟",
        topic1D: "خدمة متخصصة تهدف إلى تقديم استشارات قانونية وشرعية تتعلق بالأوقاف، تشمل إدارة الأوقاف، عزل الناظر ومحاسبته، وصياغة الوقفيات والوصايا وتوثيقها أمام المحاكم المختصة، بالإضافة إلى استكمال الإجراءات القانونية لتراخيص الوقفية وحمايتها من أي تصرفات غير قانونية.",

        topic2H: "ما يميزنا في تقديم خدمة الأوقاف والنظارة والوصايا؟",
        topic2Dli1: "✅ خبرة في القضايا الوقفية: لدينا فريق قانوني متخصص في التعامل مع القضايا الوقفية وإدارة الأوقاف وفقاً لأحكام الشريعة الإسلامية.",
        topic2Dli2: "✅ صياغة الوقفيات والوصايا: نقدم خدمة صياغة الوقفيات والوصايا بطريقة دقيقة وقانونية لضمان تنفيذ رغبات المتبرع أو الموصي.",
        topic2Dli3: "✅ إثبات الوقفيات أمام المحكمة: نساعد في تقديم جميع المستندات اللازمة لإثبات الوقفيات والوصايا أمام المحاكم.",
        topic2Dli4: "✅ محاسبة النظار: نعمل على عزل الناظر ومحاسبته في حالة الإخلال بواجباته أو سوء إدارة الوقف.",
        topic2Dli5: "✅ استكمال تراخيص الوقفية: نساعد في استكمال الإجراءات القانونية الخاصة بتراخيص الأوقاف لدى الجهات الحكومية المعنية.",

        topic3H: "آلية العمل في خدمات الأوقاف والنظارة والوصايا",
        topic3Dli1: "استشارة قانونية: حجز موعد مع مستشار قانوني لمناقشة احتياجاتك في مجال الأوقاف والنظارة والوصايا.",
        topic3Dli2: "صياغة الوقفية والوصية: مساعدتك في صياغة الوثائق اللازمة للوقف أو الوصية وفقًا للشرع والقانون.",
        topic3Dli3: "إثبات الوقفية أمام المحكمة: تقديم كافة المستندات اللازمة لإثبات الوقفية أو الوصية أمام المحكمة المختصة.",
        topic3Dli4: "إجراءات عزل الناظر: إذا لزم الأمر، نقدم استشارات حول كيفية عزل الناظر ومحاسبته في حالة الإخلال بواجباته.",
        topic3Dli5: "استكمال تراخيص الوقفية: متابعة جميع الإجراءات القانونية اللازمة للحصول على التراخيص اللازمة للوقف.",

        topic4H: "خدماتنا تشمل: ",
        topic4Dli1: "📜 صياغة الوقفيات والوصايا: تقديم استشارات قانونية لصياغة الوقفيات والوصايا بما يتوافق مع الشريعة الإسلامية.",
        topic4Dli2: "⚖ إثبات الوقفيات أمام المحاكم: تمثيلك أمام المحكمة لإثبات الوقفية بشكل قانوني وشرعي.",
        topic4Dli3: "📅 إجراءات عزل الناظر: تقديم استشارات قانونية حول كيفية عزل الناظر في حال وجود إخلال من جانبه.",
        topic4Dli4: "📋 استكمال تراخيص الوقفية: مساعدتك في إتمام الإجراءات الخاصة بتراخيص الوقف في الجهات المختصة.",
    },
    {
        id: 11,
        name: "الملكية الفكرية وتوثيق العلامات التجارية",
        img: card11,
        Pageimg: card11P,
        description: "تقديم خدمات حفظ الحقوق والحماية الفكرية للعلامات التجارية والاختراعات وتسجيلها",
        note: "في شركة إبراهيم الزهراني وأحمد المالكي للمحاماة والاستشارات القانونية، نقدم خدمات شاملة لحفظ الحقوق وحماية الملكية الفكرية للعلامات التجارية، والاختراعات، بالإضافة إلى تسجيلها وفقًا للقوانين والأنظمة المعمول بها في المملكة العربية السعودية. نحن نعمل على حماية أفكارك وابتكاراتك لضمان حقوقك الفكرية ومنع أي انتهاك لها.",

        topic1H: "ما هي خدمة الملكية الفكرية ؟",
        topic1D: "خدمة تهدف إلى تقديم استشارات وحلول قانونية لحماية الحقوق الفكرية بما في ذلك تسجيل العلامات التجارية، وبراءات الاختراع، وحقوق المؤلف، بالإضافة إلى مساعدة أصحاب الأفكار المبتكرة على ضمان حماية إبداعاتهم من التعدي أو الاستغلال غير المشروع.",

        topic2H: "ما يميزنا في تقديم خدمات الملكية الفكرية",
        topic2Dli1: "✅ تسجيل وحماية العلامات التجارية: نقدم خدمات تسجيل العلامات التجارية وحمايتها من أي محاولات تزوير أو تقليد.",
        topic2Dli2: "✅ حماية الاختراعات والابتكارات: نقدم استشارات قانونية حول كيفية حماية الاختراعات والحصول على براءات اختراع لضمان حقوق المخترعين.",
        topic2Dli3: "✅ استشارات قانونية شاملة: نقدم استشارات قانونية متكاملة لأصحاب الشركات والمبدعين حول كيفية حماية حقوقهم الفكرية.",
        topic2Dli4: "✅ حلول قانونية مبتكرة: نساعد عملاءنا في التوصل إلى حلول قانونية لحماية إبداعاتهم وحفظ حقوقهم في ظل التشريعات الحالية.",
        topic2Dli5: "✅ متابعة القضايا المتعلقة بالملكية الفكرية: نتابع القضايا المتعلقة بانتهاك الملكية الفكرية ونسعى للحصول على التعويضات المناسبة لعملائنا.",

        topic3H: "آلية العمل في خدمات الملكية الفكرية",
        topic3Dli1: "استشارة قانونية: حجز موعد مع مستشار قانوني لتحديد نوع الحماية الفكرية التي تحتاج إليها.",
        topic3Dli2: "تقديم طلبات التسجيل: مساعدتك في تقديم طلبات لتسجيل العلامات التجارية أو براءات الاختراع لدى الجهات المعنية.",
        topic3Dli3: "متابعة الإجراءات القانونية: متابعة عمليات التسجيل والتحقق من حماية حقوقك الفكرية بشكل كامل.",
        topic3Dli4: "التحقيق في التعدي: إذا تم التعدي على حقوقك الفكرية، نقدم لك استشارات قانونية بشأن كيفية التعامل مع هذه المسائل أمام الجهات المختصة",

        topic4H: "خدماتنا تشمل: ",
        topic4Dli1: "📜 تسجيل العلامات التجارية: مساعدتك في تسجيل علامتك التجارية وحمايتها من التعدي.",
        topic4Dli2: "🔬 حماية الاختراعات: تقديم استشارات وخدمات لتسجيل براءات الاختراع لحماية اختراعاتك.",
        topic4Dli3: "🎨 حقوق المؤلف: حماية حقوقك في الأعمال الأدبية والفنية والبرمجية.",
        topic4Dli4: "⚖ مكافحة التعدي على الملكية الفكرية: تمثيلك قانونياً في القضايا المتعلقة بتعدي الغير على حقوق الملكية الفكرية.",
    },
    {
        id: 12,
        name: "تصفية التركات",
        img: card12,
        Pageimg: card12P,
        description: "بدءاً من استخراج صك حصر الورثة إلى تقسيم التركة شرعياً سواءً كانت رضائية أو قضائية مع تحديد الأنصبة والحصص",
        note: "في شركة إبراهيم الزهراني وأحمد المالكي للمحاماة والاستشارات القانونية، نقدم خدمات تصفية التركات بشكل كامل، بدءًا من استخراج صك حصر الورثة إلى تقسيم التركة شرعياً سواء كانت رضائية أو قضائية، مع تحديد الأنصبة والحصص الشرعية لكل وريث. نحن نعمل على ضمان العدالة والامتثال الكامل للشريعة الإسلامية في تقسيم التركة وحماية حقوق جميع الأطراف المعنية.",

        topic1H: "ما هي خدمة تصفية التركات؟",
        topic1D: "تصفية التركات هي عملية قانونية تهدف إلى تحديد ورثة المتوفى، واستخراج صك حصر الورثة، ثم تقسيم التركة وفقًا للأحكام الشرعية المتبعة في المملكة العربية السعودية. يشمل ذلك تحديد الأنصبة الشرعية لكل وريث وفقًا للشريعة الإسلامية.",

        topic2H: "ما يميزنا في تقديم خدمة تصفية التركات ؟",
        topic2Dli1: "✅ الامتثال الكامل للشريعة الإسلامية: نضمن أن جميع الإجراءات تتماشى مع أحكام الشريعة في تقسيم التركات.",
        topic2Dli2: "✅ إجراءات سريعة وفعالة: نقدم لك حلاً قانونياً سريعاً لضمان أن التركة يتم تقسيمها بأسرع وقت ممكن.",
        topic2Dli3: "✅ خدمة قانونية شاملة: من استخراج صك حصر الورثة إلى تقسيم التركة بشكل عادل.",
        topic2Dli4: "✅ حلول مرنة: نعمل على تقديم خيارات قانونية مناسبة سواء كانت التسوية رضائية أو قضائية.",
        topic2Dli5: "✅ حماية حقوق جميع الأطراف: نحرص على تحقيق العدالة لجميع الورثة مع ضمان حقوق كل منهم.",

        topic3H: "آلية العمل في تصفية التركات",
        topic3Dli1: "📅 تحديد موعد استشارة: حجز موعد مع مستشار قانوني لفحص التركة وتحديد كيفية تقسيمها.",
        topic3Dli2: "📑 إجراءات استخراج صك حصر الورثة: استخراج صك حصر الورثة من المحكمة.",
        topic3Dli3: "⚖ التفاوض بين الورثة: إذا كان ذلك ممكنًا، نقوم بتسهيل التفاوض بين الورثة لتسوية التركة رضائيًا.",
        topic3Dli4: "📝 التقاضي أمام المحكمة: إذا لزم الأمر، نقوم بتمثيل الورثة في المحكمة لتقسيم التركة بشكل قضائي.",

        topic4H: "خدماتنا تشمل: ",
        topic4Dli1: "📑 استخراج صك حصر الورثة: تقديم استشارات قانونية ومساعدة في استخراج صك حصر الورثة من الجهات المختصة.",
        topic4Dli2: "⚖ تقسيم التركة: تقديم استشارات شرعية وقانونية في تقسيم التركة بشكل عادل، سواء كانت تسوية رضائية أو إجراءات قضائية.",
        topic4Dli3: "📜 تحديد الأنصبة الشرعية: حساب وتوزيع الأنصبة الشرعية لكل وريث بشكل دقيق وفقًا للشريعة الإسلامية.",
        topic4Dli4: "📝 إعداد الوثائق القانونية: صياغة الوثائق اللازمة لإتمام عملية تقسيم التركة بشكل قانوني ورسمي.",
    },
]

export { ServicesData };