import React, { useEffect, useRef, useState } from 'react';
import './Nav.css';
import Navbar from 'react-bootstrap/Navbar';
import { BiMenuAltLeft, BiX } from "react-icons/bi";
import Nlogo from '../../assets/nav-logo.webp';
import { Link } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import classNames from 'classnames';
import { FaInstagram } from "react-icons/fa";
import { GrMapLocation } from "react-icons/gr";
import { SlSocialLinkedin } from "react-icons/sl";
import { LiaSnapchat } from 'react-icons/lia';
import { FaXTwitter } from 'react-icons/fa6';

function PageNav({ NavTo2, NavId2, NavName2 }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isNavBg, setIsNavBg] = useState(false);
    const [activeNav, setActiveNav] = useState('header');
    const navContainerRef = useRef(null);
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const toggleMenu = () => setIsMenuOpen(prev => !prev);
    const closeMenu = () => setIsMenuOpen(false);


    const handleClickOutside = (event) => {
        if (navContainerRef.current && !navContainerRef.current.contains(event.target)) {
            closeMenu();
        }
    };

    const changeBg = () => {
        setIsNavBg(window.scrollY >= 90);
    };

    const controlNavbar = () => {
        if (window.scrollY > lastScrollY) {
            // Scrolling Down → Hide Navbar
            setIsVisible(false);
        } else if (window.scrollY < lastScrollY) {
            // Scrolling Up → Show Navbar
            setIsVisible(true);
        }
        setLastScrollY(window.scrollY); // Update last scroll position
    };

    useEffect(() => {
        const onScroll = () => {
            changeBg();
            controlNavbar();
            closeMenu();
        };

        window.addEventListener('scroll', onScroll);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('scroll', onScroll);
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastScrollY]);



    useEffect(() => {
        const handleScroll = () => {
            const sections = ['contact', 'Blogpage', "footer"];
            let currentSection = 'header';

            for (let i = 0; i < sections.length; i++) {
                const section = document.getElementById(sections[i]);
                if (section) {
                    const sectionTop = section.getBoundingClientRect().top + window.scrollY;
                    const sectionBottom = sectionTop + section.offsetHeight;

                    if (window.pageYOffset >= sectionTop - 500 && window.pageYOffset < sectionBottom - 100) {
                        currentSection = `#${sections[i]}`;
                        break;
                    }
                }
            }

            setActiveNav(currentSection);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <section id='navbar'>
            <Navbar
                fixed="top"
                expand="lg"
                className={classNames(
                    "navbar navbar-bg",
                    { "navbar-bg": isNavBg },
                    { "visibleNav": isVisible, "hiddenNav": !isVisible }
                )}
            >
                <div className={classNames('menu menu-bg', { 'menu-bg': isNavBg })}>
                    <i><BiMenuAltLeft onClick={toggleMenu} /></i>
                </div>
                <div className="nav-container" ref={navContainerRef}>
                    <div
                        className={classNames('topnav', { 'Svisible': isMenuOpen, 'closed': !isMenuOpen })}
                        id='nav-container'
                    >
                        <div className="close-btn" onClick={(e) => { e.stopPropagation(); closeMenu(); }}>
                            <BiX />
                        </div>
                        <Link to="/" className='Hactive'>الرئيسية</Link>
                        <LinkS to={NavTo2} className={activeNav === NavId2 ? 'Nactive touch' : 'touch'}>{NavName2}</LinkS>
                        <Link to="/blog" className={activeNav === '#Blogpage' ? 'Nactive touch' : 'touch'}>المدونة</Link>

                        <div className='nave-links' dir='ltr'>
                            <a target="_blank"
                                rel="noreferrer" href="https://www.google.com/maps?ll=21.613534,39.154892&z=16&t=m&hl=en&gl=EG&mapclient=embed&cid=14346717577956034144"><i className='nav-Linkbt'><GrMapLocation /></i></a>
                            <a target="_blank"
                                rel="noreferrer" href="https://www.linkedin.com/in/legal-company-378620328/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"><i className='nav-Linkbt'><SlSocialLinkedin /></i></a>
                            <a target="_blank"
                                rel="noreferrer" href="https://snapchat.com/t/W0NxoL9c"><i className='nav-Linkbt'><LiaSnapchat /></i></a>
                            <a target="_blank"
                                rel="noreferrer" href="https://x.com/ialawcom?s=11"><i className='nav-Linkbt'><FaXTwitter /></i></a>
                            <a target="_blank"
                                rel="noreferrer" href="https://www.instagram.com/legalcompany2024?igsh=M3I4bjYxcjQycGdy&utm_source=qr"><i className='nav-Linkbt'><FaInstagram /></i></a>
                        </div>
                    </div>
                    <Navbar.Brand href="/" className='logo'>
                        <img
                            src={Nlogo} alt="Nav Logo" />
                    </Navbar.Brand>
                </div>
            </Navbar>
        </section>
    );
}

export default PageNav;
