import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet-async';
import BlogPage from './Pages/BlogPage/BlogPage.jsx';
import HomePage from './Pages/Homepage.jsx';
import Login from './Pages/loginpage/Loginpage.jsx';
import AdminPage from './Pages/Adminpage/AdminPage.jsx';
import BlogDetailPage from './Pages/BlogDetailPage/BlogDetailPage.jsx';
import ExistingBlogsPage from './Pages/ExistingBlogsPage/ExistingBlogsPage.jsx';
import ServPage from './Pages/ServPage/ServPage.jsx';

// Component to handle reload on navigation
const ReloadOnNavigate = () => {
    const location = useLocation();
    const [prevLocation, setPrevLocation] = useState(location.pathname);

    useEffect(() => {
        // Only reload if the location has changed (not on the initial render)
        if (prevLocation !== location.pathname) {
            window.location.reload();
        }
        setPrevLocation(location.pathname);
    }, [location, prevLocation]);

    return null; // This component doesn't render anything
};

const App = () => {
    useEffect(() => {
        AOS.init({
            offset: 0,
            duration: 800,
            easing: 'ease',
        });

        const handleResize = () => {
            AOS.refresh();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div id='main'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>شركة ابراهيم الزهراني واحمد المالكي للمحاماة والاستشارات القانونية</title>
            </Helmet>
            <Router>
                <ReloadOnNavigate /> {/* Use the reload component */}
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/blog" element={<BlogPage />} />
                    <Route path="/blog/:id/:title" element={<BlogDetailPage />} />
                    <Route path="/existing-blogs" element={<ExistingBlogsPage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/admin" element={<AdminPage />} />

                    <Route path="/services/:ScardId" element={<ServPage />} />
                    {/* Redirecting specific paths to the home page */}
                    <Route path="/contact" element={<Navigate to="/" />} />
                    <Route path="/about" element={<Navigate to="/" />} />
                    <Route path="/serv" element={<Navigate to="/" />} />
                    <Route path="/showcase" element={<Navigate to="/" />} />
                    <Route path="/site.webmanifest" element={<Navigate to="/" />} />

                    {/* Add a catch-all route for undefined paths */}
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Router>
        </div>
    );
};

export default App;
