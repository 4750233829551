import React, { useEffect } from 'react';
import './ServPage.css';
import { ServicesData } from '../../assets/ServData';
import NavPage from '../../components/Nav/PageNav';
import { useParams } from 'react-router-dom';
import stampLogo from '../../assets/logo2-2.webp'
import { TbTargetArrow } from "react-icons/tb";
import { GoShieldCheck } from 'react-icons/go';
import { HiOutlineLightBulb } from "react-icons/hi";
import PageFooter from '../../components/Footer/PageFooter';
import Icon from '../../components/header/Icon';
const ServPage = () => {
    const { ScardId } = useParams();
    const servData = ServicesData.find((serv) => serv.id === parseInt(ScardId));


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div id='servPage'>
            <NavPage NavTo2={'footer'} NavId2={"#footer"} NavName2={"تواصل"} />

            <div className='main-bg-containerr'>
                <div className='main-bg'>
                    <div className='serv-content-container'>
                        <div className='serv-content'>
                            <section className='serv-content-header'>
                                <article className='arti-header'>
                                    <div className='serv-header-top'>
                                        <img src={servData.Pageimg} alt={servData.name} />
                                        <div className='serv-header-content'>
                                            <h1>{servData.name}</h1>
                                            <p>{servData.description}</p>
                                        </div>
                                    </div>
                                </article>
                            </section>

                            <div>
                                <p>{servData.note}</p>
                            </div>
                            <div className="serv-valus-container">
                                <div className='serv-valus'>
                                    <div className='valu-line'></div>
                                    <div className='serv-valus-item'>
                                        <i><GoShieldCheck /></i>
                                        <p>سرية تامة</p>
                                    </div>
                                    <div className='serv-valus-item'>
                                        <i><HiOutlineLightBulb /></i>
                                        <p>حلول مبتكرة</p>
                                    </div>
                                    <div className='serv-valus-item'>
                                        <i><TbTargetArrow /></i>
                                        <p>تحليل دقيق</p>
                                    </div>
                                </div>
                                <div className='serv-btns'>
                                    <a
                                        className="serv-btn"
                                        href="tel:+966551155707"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        أتصل الان
                                    </a>
                                    <a

                                        className="serv-btn"
                                        href="https://api.whatsapp.com/send?phone=966551155707"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        واتساب
                                    </a>
                                </div>
                            </div>

                            <section className='serv-content-articals'>
                                {servData.topic1H && servData.topic1D && (
                                    <article className='serv-content-artical serv-artical-1'>
                                        <h2>{servData.topic1H}</h2>
                                        <p>{servData.topic1D}</p>
                                    </article>
                                )}

                                {servData.topic2H && (
                                    (() => {
                                        const listItems = [
                                            servData.topic2Dli1,
                                            servData.topic2Dli2,
                                            servData.topic2Dli3,
                                            servData.topic2Dli4,
                                            servData.topic2Dli5
                                        ].filter(item => item); // Remove empty items

                                        return listItems.length > 0 ? (
                                            <article className='serv-content-artical serv-artical-2'>
                                                <h2>{servData.topic2H}</h2>
                                                <ul>
                                                    {listItems.map((item, index) => (
                                                        <li key={index}>{item}</li>
                                                    ))}
                                                </ul>
                                            </article>
                                        ) : null;
                                    })()
                                )}

                                {servData.topic3H && (
                                    (() => {
                                        const listItems = [
                                            servData.topic3Dli1,
                                            servData.topic3Dli2,
                                            servData.topic3Dli3,
                                            servData.topic3Dli4,
                                            servData.topic3Dli5
                                        ].filter(item => item);

                                        return listItems.length > 0 ? (
                                            <article className='serv-content-artical serv-artical-3'>
                                                <h2>{servData.topic3H}</h2>
                                                <ol>
                                                    {listItems.map((item, index) => (
                                                        <li key={index}>{item}</li>
                                                    ))}
                                                </ol>
                                            </article>
                                        ) : null;
                                    })()
                                )}

                                {servData.topic4H && (
                                    (() => {
                                        const listItems = [
                                            servData.topic4Dli1,
                                            servData.topic4Dli2,
                                            servData.topic4Dli3,
                                            servData.topic4Dli4
                                        ].filter(item => item);

                                        return listItems.length > 0 ? (
                                            <article className='serv-content-artical serv-artical-4'>
                                                <h2>{servData.topic4H}</h2>
                                                <ol>
                                                    {listItems.map((item, index) => (
                                                        <li key={index}>{item}</li>
                                                    ))}
                                                </ol>
                                            </article>
                                        ) : null;
                                    })()
                                )}
                            </section>

                            <div className='serv-btns'>
                                <a
                                    className="serv-btn"
                                    href="tel:+966551155707"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    أتصل الان
                                </a>
                                <a

                                    className="serv-btn"
                                    href="https://api.whatsapp.com/send?phone=966551155707"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    واتساب
                                </a>
                            </div>
                            <div className='stampLogo'>
                                <img src={stampLogo} alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PageFooter />
            <Icon />
        </div>
    );
};

export default ServPage;
