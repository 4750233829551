import React from 'react'
import './Services.css'
import { ServicesData } from '../../assets/ServData'
import { Link } from 'react-router-dom'
import logo from "../../assets/nav-logo.webp"



function Services() {


    return (
        <div>
            <section id="serv">
                <div className="sec-border"
                >
                    <div className="about-container">
                        <h1 ><span>خدماتنا</span></h1>
                        <div className="serv-container">
                            {ServicesData.map((Scard) => (
                                <div key={Scard.id} className="serv-card" >
                                    <div className='hover-bg'>
                                        <Link to={`/services/${Scard.id}?name=${Scard.name}`}>
                                            <div className='hover-div'>
                                                <img src={logo} width={"60px"} alt="" />
                                                <br />
                                                <p>قراءة المزيد</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <img src={Scard.img} alt="img" />
                                    <div className='card-conrent'>
                                        <h5>{Scard.name}</h5>
                                        <p>{Scard.description}
                                            <br />
                                            <p className='more-btn'><strong>المزيد &gt;</strong></p>
                                        </p>

                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services