import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import './BlogDetailPage.css';
import { PulseLoader } from 'react-spinners';
import PageNav from '../../components/Nav/PageNav';
import Contact from '../../components/Contact/Contact';
import PageFooter from '../../components/Footer/PageFooter';
import Rblog from '../../components/Rblog/Rblog';
import axios from 'axios';

const BlogDetailPage = () => {
    const [blog, setBlog] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/blogs/blog/${id}`);
                setBlog(response.data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchBlogs();
    });


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id]);

    if (loading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#ffffff", height: "100vh" }}>
                <PulseLoader color="#C79149" />
            </div>
        );
    }

    if (error || !blog) {
        return <div>تم حذف هذة المدونة</div>;
    }

    return (
        <div id='blog-detail'>
            <Helmet>
                <title>{blog.title}</title>
                <meta name="description" content={blog.content.slice(0, 150)} />
                <meta name="keywords" content={blog.content.slice(0, 150)} />
                <meta property="og:title" content={blog.title} />
                <meta property="og:description" content={blog.content.slice(0, 150)} />
                <meta property="og:image" content={blog.image} />
                <meta property="og:url" content={`https://i-a-lawco.com/blog/${id}/${blog.title}`} />
                <link rel="canonical" href={`https://i-a-lawco.com/blog/${id}/${blog.title}`} />
                <meta property="og:type" content="article" />
                <meta property="og:site_name" content="El-Malky" />
            </Helmet>

            <PageNav NavTo2={'contact'} NavId2={"#contact"} NavName2={"تواصل"} />
            <article className='blog-detail' dir='rtl'>
                <div className='blog-detail-table'>
                    {blog.image && <img src={blog.image} alt={blog.title} style={{ width: '100%', height: 'auto' }} />}
                    <br />
                    <h1><strong>{blog.title}</strong></h1>
                    <br />
                    <h6><small>
                        {blog.createdAt ? new Date(blog.createdAt).toLocaleDateString() : 'Unknown date'}
                    </small></h6>
                    <br />
                    <div className='conentDit' dangerouslySetInnerHTML={{ __html: blog.content }} />
                </div>
                <div className='border-end'></div>
            </article>
            <Rblog />
            <Contact />
            <PageFooter />
        </div>
    );
};

export default BlogDetailPage;
